/* eslint-disable no-throw-literal */
import * as types from '../mutation-types';
import Profanity from '../../api/profanity';

export const state = {
  profanityList: [],
  cacheProfanityList: [],
};

export const getters = {
  getProfanityList($state) {
    return $state.profanityList;
  },
};

export const actions = {
  create: async ({ commit }, profanity) => {
    try {
      const response = await Profanity.create(profanity);

      commit(types.default.ADD_PROFANITY_LIST, response.data);
    } catch (error) {
      const { duplicates = [] } = error.response.data;

      if (duplicates.length === 0) {
        throw 'ERROR';
      }

      throw 'DUPLICATED';
    }
  },
  read: async ({ commit }) => {
    try {
      const response = await Profanity.get();

      commit(types.default.SET_PROFANITY_LIST, response.data);
    } catch (error) {
      commit(types.default.SET_PROFANITY_LIST, []);
    }
  },
  delete: async ({ commit }, profanity) => {
    try {
      await Profanity.delete(profanity.id);

      commit(types.default.DELETE_PROFANITY_LIST, profanity);
    } catch (error) {
      commit(types.default.SET_PROFANITY_LIST, []);
    }
  },
  search: async ({ commit }, params) => {
    try {
      const response = await Profanity.search(params);

      commit(types.default.SEARCH_PROFANITY_LIST, response.data);
    } catch (error) {
      commit(types.default.SET_PROFANITY_LIST, []);
    }
  },
};

export const mutations = {
  [types.default.SET_PROFANITY_LIST]($state, data) {
    $state.profanityList = data;
    $state.cacheProfanityList = data;
  },
  [types.default.ADD_PROFANITY_LIST]($state, data) {
    const list = [...$state.cacheProfanityList, ...data];

    $state.profanityList = list;
    $state.cacheProfanityList = list;
  },
  [types.default.DELETE_PROFANITY_LIST]($state, data) {
    const list = $state.profanityList.filter(
      profanity => profanity.id !== data.id
    );

    $state.profanityList = list;
    $state.cacheProfanityList = list;
  },
  [types.default.SEARCH_PROFANITY_LIST]($state, data) {
    $state.profanityList = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
