/* global axios */

import ApiClient from './ApiClient';

class Profanity extends ApiClient {
  constructor() {
    super('bad_words');
  }

  search(params) {
    return axios.get(this.url, { params });
  }
}

export default new Profanity();
