const countries = [
  {
    name: 'Afghanistan',
    dial_code: '+93',
    emoji: '🇦🇫',
    id: 'AF',
    name_zh_TW: '阿富汗',
    name_th: 'อัฟกานิสถาน',
  },
  {
    name: 'Aland Islands',
    dial_code: '+358',
    emoji: '🇦🇽',
    id: 'AX',
    name_zh_TW: '奧蘭群島',
    name_th: 'หมู่เกาะอัลแลนด์',
  },
  {
    name: 'Albania',
    dial_code: '+355',
    emoji: '🇦🇱',
    id: 'AL',
    name_zh_TW: '阿爾巴尼亞',
    name_th: 'แอลเบเนีย',
  },
  {
    name: 'Algeria',
    dial_code: '+213',
    emoji: '🇩🇿',
    id: 'DZ',
    name_zh_TW: '阿爾及利亞',
    name_th: 'แอลจีเรีย',
  },
  {
    name: 'AmericanSamoa',
    dial_code: '+1684',
    emoji: '🇦🇸',
    id: 'AS',
    name_zh_TW: '美屬薩摩亞',
    name_th: 'อเมริกันซามัว',
  },
  {
    name: 'Andorra',
    dial_code: '+376',
    emoji: '🇦🇩',
    id: 'AD',
    name_zh_TW: '安道爾',
    name_th: 'อันดอร์รา',
  },
  {
    name: 'Angola',
    dial_code: '+244',
    emoji: '🇦🇴',
    id: 'AO',
    name_zh_TW: '安哥拉',
    name_th: 'แองโกลา',
  },
  {
    name: 'Anguilla',
    dial_code: '+1264',
    emoji: '🇦🇮',
    id: 'AI',
    name_zh_TW: '安圭拉',
    name_th: 'แองกวิลลา',
  },
  {
    name: 'Antarctica',
    dial_code: '+672',
    emoji: '🇦🇶',
    id: 'AQ',
    name_zh_TW: '南極洲',
    name_th: 'แอนตาร์กติกา',
  },
  {
    name: 'Antigua and Barbuda',
    dial_code: '+1268',
    emoji: '🇦🇬',
    id: 'AG',
    name_zh_TW: '安地卡及巴布達',
    name_th: 'แอนติกาและบาร์บูดา',
  },
  {
    name: 'Argentina',
    dial_code: '+54',
    emoji: '🇦🇷',
    id: 'AR',
    name_zh_TW: '阿根廷',
    name_th: 'อาร์เจนตินา',
  },
  {
    name: 'Armenia',
    dial_code: '+374',
    emoji: '🇦🇲',
    id: 'AM',
    name_zh_TW: '亞美尼亞',
    name_th: 'อาร์เมเนีย',
  },
  {
    name: 'Aruba',
    dial_code: '+297',
    emoji: '🇦🇼',
    id: 'AW',
    name_zh_TW: '阿魯巴島',
    name_th: 'อารูบา',
  },
  {
    name: 'Australia',
    dial_code: '+61',
    emoji: '🇦🇺',
    id: 'AU',
    name_zh_TW: '澳大利亞',
    name_th: 'ออสเตรเลีย',
  },
  {
    name: 'Austria',
    dial_code: '+43',
    emoji: '🇦🇹',
    id: 'AT',
    name_zh_TW: '奧地利',
    name_th: 'ออสเตรีย',
  },
  {
    name: 'Azerbaijan',
    dial_code: '+994',
    emoji: '🇦🇿',
    id: 'AZ',
    name_zh_TW: '亞塞拜然',
    name_th: 'อาเซอร์ไบจาน',
  },
  {
    name: 'Bahamas',
    dial_code: '+1242',
    emoji: '🇧🇸',
    id: 'BS',
    name_zh_TW: '巴哈馬',
    name_th: 'บาฮามาส',
  },
  {
    name: 'Bahrain',
    dial_code: '+973',
    emoji: '🇧🇭',
    id: 'BH',
    name_zh_TW: '巴林',
    name_th: 'บาห์เรน',
  },
  {
    name: 'Bangladesh',
    dial_code: '+880',
    emoji: '🇧🇩',
    id: 'BD',
    name_zh_TW: '孟加拉',
    name_th: 'บังกลาเทศ',
  },
  {
    name: 'Barbados',
    dial_code: '+1246',
    emoji: '🇧🇧',
    id: 'BB',
    name_zh_TW: '巴貝多',
    name_th: 'บาร์เบโดส',
  },
  {
    name: 'Belarus',
    dial_code: '+375',
    emoji: '🇧🇾',
    id: 'BY',
    name_zh_TW: '白俄羅斯',
    name_th: 'เบลารุส',
  },
  {
    name: 'Belgium',
    dial_code: '+32',
    emoji: '🇧🇪',
    id: 'BE',
    name_zh_TW: '比利時',
    name_th: 'เบลเยียม',
  },
  {
    name: 'Belize',
    dial_code: '+501',
    emoji: '🇧🇿',
    id: 'BZ',
    name_zh_TW: '貝里斯',
    name_th: 'เบลีซ',
  },
  {
    name: 'Benin',
    dial_code: '+229',
    emoji: '🇧🇯',
    id: 'BJ',
    name_zh_TW: '貝南',
    name_th: 'เบนิน',
  },
  {
    name: 'Bermuda',
    dial_code: '+1441',
    emoji: '🇧🇲',
    id: 'BM',
    name_zh_TW: '百慕達',
    name_th: 'เบอร์มิวดา',
  },
  {
    name: 'Bhutan',
    dial_code: '+975',
    emoji: '🇧🇹',
    id: 'BT',
    name_zh_TW: '不丹',
    name_th: 'ภูฏาน',
  },
  {
    name: 'Bolivia, Plurinational State of',
    dial_code: '+591',
    emoji: '🇧🇴',
    id: 'BO',
    name_zh_TW: '玻利維亞',
    name_th: 'โบลิเวีย',
  },
  {
    name: 'Bosnia and Herzegovina',
    dial_code: '+387',
    emoji: '🇧🇦',
    id: 'BA',
    name_zh_TW: '波士尼亞與赫塞哥維納',
    name_th: 'บอสเนียและเฮอร์เซโกวีนา',
  },
  {
    name: 'Botswana',
    dial_code: '+267',
    emoji: '🇧🇼',
    id: 'BW',
    name_zh_TW: '波札那',
    name_th: 'บอตสวานา',
  },
  {
    name: 'Brazil',
    dial_code: '+55',
    emoji: '🇧🇷',
    id: 'BR',
    name_zh_TW: '巴西',
    name_th: 'บราซิล',
  },
  {
    name: 'British Indian Ocean Territory',
    dial_code: '+246',
    emoji: '🇮🇴',
    id: 'IO',
    name_zh_TW: '英屬印度洋領地',
    name_th: 'เขตแดนอินเดียตะวันตกของอังกฤษ',
  },
  {
    name: 'Brunei Darussalam',
    dial_code: '+673',
    emoji: '🇧🇳',
    id: 'BN',
    name_zh_TW: '汶萊達魯薩蘭國',
    name_th: 'บรูไน ดารุสซาลาม',
  },
  {
    name: 'Bulgaria',
    dial_code: '+359',
    emoji: '🇧🇬',
    id: 'BG',
    name_zh_TW: '保加利亞',
    name_th: 'บัลแกเรีย',
  },
  {
    name: 'Burkina Faso',
    dial_code: '+226',
    emoji: '🇧🇫',
    id: 'BF',
    name_zh_TW: '布吉納法索',
    name_th: 'บูร์กินาฟาโซ',
  },
  {
    name: 'Burundi',
    dial_code: '+257',
    emoji: '🇧🇮',
    id: 'BI',
    name_zh_TW: '蒲隆地',
    name_th: 'บุรุนดี',
  },
  {
    name: 'Cambodia',
    dial_code: '+855',
    emoji: '🇰🇭',
    id: 'KH',
    name_zh_TW: '柬埔寨',
    name_th: 'กัมพูชา',
  },
  {
    name: 'Cameroon',
    dial_code: '+237',
    emoji: '🇨🇲',
    id: 'CM',
    name_zh_TW: '喀麥隆',
    name_th: 'แคเมอรูน',
  },
  {
    name: 'Canada',
    dial_code: '+1',
    emoji: '🇨🇦',
    id: 'CA',
    name_zh_TW: '加拿大',
    name_th: 'แคนาดา',
  },
  {
    name: 'Cape Verde',
    dial_code: '+238',
    emoji: '🇨🇻',
    id: 'CV',
    name_zh_TW: '維德角',
    name_th: 'เคปเวิร์ด',
  },
  {
    name: 'Cayman Islands',
    dial_code: '+345',
    emoji: '🇰🇾',
    id: 'KY',
    name_zh_TW: '開曼群島',
    name_th: 'หมู่เกาะเคย์แมน',
  },
  {
    name: 'Central African Republic',
    dial_code: '+236',
    emoji: '🇨🇫',
    id: 'CF',
    name_zh_TW: '中非共和國',
    name_th: 'สาธารณรัฐแอฟริกากลาง',
  },
  {
    name: 'Chad',
    dial_code: '+235',
    emoji: '🇹🇩',
    id: 'TD',
    name_zh_TW: '查德',
    name_th: 'ชาด',
  },
  {
    name: 'Chile',
    dial_code: '+56',
    emoji: '🇨🇱',
    id: 'CL',
    name_zh_TW: '智利',
    name_th: 'ชิลี',
  },
  {
    name: 'China',
    dial_code: '+86',
    emoji: '🇨🇳',
    id: 'CN',
    name_zh_TW: '中國',
    name_th: 'สาธารณรัฐประชาชนจีน',
  },
  {
    name: 'Christmas Island',
    dial_code: '+61',
    emoji: '🇨🇽',
    id: 'CX',
    name_zh_TW: '聖誕島',
    name_th: 'เกาะคริสต์มาส',
  },
  {
    name: 'Cocos (Keeling) Islands',
    dial_code: '+61',
    emoji: '🇨🇨',
    id: 'CC',
    name_zh_TW: '科克斯（基靈）群島',
    name_th: 'หมู่เกาะโคโคส (คีลิง)',
  },
  {
    name: 'Colombia',
    dial_code: '+57',
    emoji: '🇨🇴',
    id: 'CO',
    name_zh_TW: '哥倫比亞',
    name_th: 'โคลอมเบีย',
  },
  {
    name: 'Comoros',
    dial_code: '+269',
    emoji: '🇰🇲',
    id: 'KM',
    name_zh_TW: '葛摩',
    name_th: 'คอโมรอส',
  },
  {
    name: 'Congo',
    dial_code: '+242',
    emoji: '🇨🇬',
    id: 'CG',
    name_zh_TW: '剛果',
    name_th: 'คองโก',
  },
  {
    name: 'Congo, The Democratic Republic of the Congo',
    dial_code: '+243',
    emoji: '🇨🇩',
    id: 'CD',
    name_zh_TW: '剛果民主共和國',
    name_th: 'คองโก, สาธารณรัฐประชาธิปไตยคองโก',
  },
  {
    name: 'Cook Islands',
    dial_code: '+682',
    emoji: '🇨🇰',
    id: 'CK',
    name_zh_TW: '庫克群島',
    name_th: 'หมู่เกาะคุก',
  },
  {
    name: 'Costa Rica',
    dial_code: '+506',
    emoji: '🇨🇷',
    id: 'CR',
    name_zh_TW: '哥斯大黎加',
    name_th: 'คอสตาริกา',
  },
  {
    name: "Cote d'Ivoire",
    dial_code: '+225',
    emoji: '🇨🇮',
    id: 'CI',
    name_zh_TW: '科特迪瓦',
    name_th: 'ไอวอรีโคสต์',
  },
  {
    name: 'Croatia',
    dial_code: '+385',
    emoji: '🇭🇷',
    id: 'HR',
    name_zh_TW: '克羅埃西亞',
    name_th: 'โครเอเชีย',
  },
  {
    name: 'Cuba',
    dial_code: '+53',
    emoji: '🇨🇺',
    id: 'CU',
    name_zh_TW: '古巴',
    name_th: 'คิวบา',
  },
  {
    name: 'Cyprus',
    dial_code: '+357',
    emoji: '🇨🇾',
    id: 'CY',
    name_zh_TW: '賽普勒斯',
    name_th: 'ไซปรัส',
  },
  {
    name: 'Czech Republic',
    dial_code: '+420',
    emoji: '🇨🇿',
    id: 'CZ',
    name_zh_TW: '捷克共和國',
    name_th: 'สาธารณรัฐเช็ก',
  },
  {
    name: 'Denmark',
    dial_code: '+45',
    emoji: '🇩🇰',
    id: 'DK',
    name_zh_TW: '丹麥',
    name_th: 'เดนมาร์ก',
  },
  {
    name: 'Djibouti',
    dial_code: '+253',
    emoji: '🇩🇯',
    id: 'DJ',
    name_zh_TW: '吉布地',
    name_th: 'จิบูตี',
  },
  {
    name: 'Dominica',
    dial_code: '+1767',
    emoji: '🇩🇲',
    id: 'DM',
    name_zh_TW: '多明尼加',
    name_th: 'โดมินิกา',
  },
  {
    name: 'Dominican Republic',
    dial_code: '+1849',
    emoji: '🇩🇴',
    id: 'DO',
    name_zh_TW: '多明尼加共和國',
    name_th: 'สาธารณรัฐโดมินิกัน',
  },
  {
    name: 'Ecuador',
    dial_code: '+593',
    emoji: '🇪🇨',
    id: 'EC',
    name_zh_TW: '厄瓜多',
    name_th: 'เอกวาดอร์',
  },
  {
    name: 'Egypt',
    dial_code: '+20',
    emoji: '🇪🇬',
    id: 'EG',
    name_zh_TW: '埃及',
    name_th: 'อียิปต์',
  },
  {
    name: 'El Salvador',
    dial_code: '+503',
    emoji: '🇸🇻',
    id: 'SV',
    name_zh_TW: '薩爾瓦多',
    name_th: 'เอลซัลวาดอร์',
  },
  {
    name: 'Equatorial Guinea',
    dial_code: '+240',
    emoji: '🇬🇶',
    id: 'GQ',
    name_zh_TW: '赤道幾內亞',
    name_th: 'อิเควทอเรียลกินี',
  },
  {
    name: 'Eritrea',
    dial_code: '+291',
    emoji: '🇪🇷',
    id: 'ER',
    name_zh_TW: '厄利垂亞',
    name_th: 'เอริเทรีย',
  },
  {
    name: 'Estonia',
    dial_code: '+372',
    emoji: '🇪🇪',
    id: 'EE',
    name_zh_TW: '愛沙尼亞',
    name_th: 'เอสโตเนีย',
  },
  {
    name: 'Ethiopia',
    dial_code: '+251',
    emoji: '🇪🇹',
    id: 'ET',
    name_zh_TW: '衣索比亞',
    name_th: 'เอธิโอเปีย',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    dial_code: '+500',
    emoji: '🇫🇰',
    id: 'FK',
    name_zh_TW: '福克蘭群島（馬爾維納斯）',
    name_th: 'หมู่เกาะฟอล์กแลนด์ (มาลวินัส)',
  },
  {
    name: 'Faroe Islands',
    dial_code: '+298',
    emoji: '🇫🇴',
    id: 'FO',
    name_zh_TW: '法羅群島',
    name_th: 'หมู่เกาะแฟโร',
  },
  {
    name: 'Fiji',
    dial_code: '+679',
    emoji: '🇫🇯',
    id: 'FJ',
    name_zh_TW: '斐濟',
    name_th: 'ฟิจิ',
  },
  {
    name: 'Finland',
    dial_code: '+358',
    emoji: '🇫🇮',
    id: 'FI',
    name_zh_TW: '芬蘭',
    name_th: 'ฟินแลนด์',
  },
  {
    name: 'France',
    dial_code: '+33',
    emoji: '🇫🇷',
    id: 'FR',
    name_zh_TW: '法國',
    name_th: 'ฝรั่งเศส',
  },
  {
    name: 'French Guiana',
    dial_code: '+594',
    emoji: '🇬🇫',
    id: 'GF',
    name_zh_TW: '法屬圭亞那',
    name_th: 'กีนีเฟรนช์',
  },
  {
    name: 'French Polynesia',
    dial_code: '+689',
    emoji: '🇵🇫',
    id: 'PF',
    name_zh_TW: '法屬玻里尼西亞',
    name_th: 'โพลินีเซียเฟรนช์',
  },
  {
    name: 'Gabon',
    dial_code: '+241',
    emoji: '🇬🇦',
    id: 'GA',
    name_zh_TW: '加彭',
    name_th: 'กาบอง',
  },
  {
    name: 'Gambia',
    dial_code: '+220',
    emoji: '🇬🇲',
    id: 'GM',
    name_zh_TW: '甘比亞',
    name_th: 'แกมเบีย',
  },
  {
    name: 'Georgia',
    dial_code: '+995',
    emoji: '🇬🇪',
    id: 'GE',
    name_zh_TW: '喬治亞州',
    name_th: 'จอร์เจีย',
  },
  {
    name: 'Germany',
    dial_code: '+49',
    emoji: '🇩🇪',
    id: 'DE',
    name_zh_TW: '德國',
    name_th: 'เยอรมนี',
  },
  {
    name: 'Ghana',
    dial_code: '+233',
    emoji: '🇬🇭',
    id: 'GH',
    name_zh_TW: '迦納',
    name_th: 'กานา',
  },
  {
    name: 'Gibraltar',
    dial_code: '+350',
    emoji: '🇬🇮',
    id: 'GI',
    name_zh_TW: '直布羅陀',
    name_th: 'ยิบรอลตาร์',
  },
  {
    name: 'Greece',
    dial_code: '+30',
    emoji: '🇬🇷',
    id: 'GR',
    name_zh_TW: '希臘',
    name_th: 'กรีซ',
  },
  {
    name: 'Greenland',
    dial_code: '+299',
    emoji: '🇬🇱',
    id: 'GL',
    name_zh_TW: '格陵蘭',
    name_th: 'กรีนแลนด์',
  },
  {
    name: 'Grenada',
    dial_code: '+1473',
    emoji: '🇬🇩',
    id: 'GD',
    name_zh_TW: '格瑞那達',
    name_th: 'เกรนาดา',
  },
  {
    name: 'Guadeloupe',
    dial_code: '+590',
    emoji: '🇬🇵',
    id: 'GP',
    name_zh_TW: '瓜德羅普島',
    name_th: 'กวาเดอลูป',
  },
  {
    name: 'Guam',
    dial_code: '+1671',
    emoji: '🇬🇺',
    id: 'GU',
    name_zh_TW: '關島',
    name_th: 'กวม',
  },
  {
    name: 'Guatemala',
    dial_code: '+502',
    emoji: '🇬🇹',
    id: 'GT',
    name_zh_TW: '瓜地馬拉',
    name_th: 'กัวเตมาลา',
  },
  {
    name: 'Guernsey',
    dial_code: '+44',
    emoji: '🇬🇬',
    id: 'GG',
    name_zh_TW: '耿西',
    name_th: 'เกิร์นซีย์',
  },
  {
    name: 'Guinea',
    dial_code: '+224',
    emoji: '🇬🇳',
    id: 'GN',
    name_zh_TW: '幾內亞',
    name_th: 'กินี',
  },
  {
    name: 'Guinea-Bissau',
    dial_code: '+245',
    emoji: '🇬🇼',
    id: 'GW',
    name_zh_TW: '幾內亞比紹',
    name_th: 'กินี-บิสเซา',
  },
  {
    name: 'Guyana',
    dial_code: '+595',
    emoji: '🇬🇾',
    id: 'GY',
    name_zh_TW: '蓋亞那',
    name_th: 'กายอานา',
  },
  {
    name: 'Haiti',
    dial_code: '+509',
    emoji: '🇭🇹',
    id: 'HT',
    name_zh_TW: '海地',
    name_th: 'เฮติ',
  },
  {
    name: 'Holy See (Vatican City State)',
    dial_code: '+379',
    emoji: '🇻🇦',
    id: 'VA',
    name_zh_TW: '梵蒂岡',
    name_th: 'นครวาติกัน',
  },
  {
    name: 'Honduras',
    dial_code: '+504',
    emoji: '🇭🇳',
    id: 'HN',
    name_zh_TW: '宏都拉斯',
    name_th: 'ฮอนดูรัส',
  },
  {
    name: 'Hong Kong',
    dial_code: '+852',
    emoji: '🇭🇰',
    id: 'HK',
    name_zh_TW: '香港',
    name_th: 'ฮ่องกง',
  },
  {
    name: 'Hungary',
    dial_code: '+36',
    emoji: '🇭🇺',
    id: 'HU',
    name_zh_TW: '匈牙利',
    name_th: 'ฮังการี',
  },
  {
    name: 'Iceland',
    dial_code: '+354',
    emoji: '🇮🇸',
    id: 'IS',
    name_zh_TW: '冰島',
    name_th: 'ไอซ์แลนด์',
  },
  {
    name: 'India',
    dial_code: '+91',
    emoji: '🇮🇳',
    id: 'IN',
    name_zh_TW: '印度',
    name_th: 'อินเดีย',
  },
  {
    name: 'Indonesia',
    dial_code: '+62',
    emoji: '🇮🇩',
    id: 'ID',
    name_zh_TW: '印尼',
    name_th: 'อินโดนีเซีย',
  },
  {
    name: 'Iran, Islamic Republic of Persian Gulf',
    dial_code: '+98',
    emoji: '🇮🇷',
    id: 'IR',
    name_zh_TW: '伊朗伊斯蘭共和國',
    name_th: 'อิหร่าน (สาธารณรัฐอิสลามของอิหร่าน)',
  },
  {
    name: 'Iraq',
    dial_code: '+964',
    emoji: '🇮🇷',
    id: 'IQ',
    name_zh_TW: '伊拉克',
    name_th: 'อิรัก',
  },
  {
    name: 'Ireland',
    dial_code: '+353',
    emoji: '🇮🇪',
    id: 'IE',
    name_zh_TW: '愛爾蘭',
    name_th: 'ไอร์แลนด์',
  },
  {
    name: 'Isle of Man',
    dial_code: '+44',
    emoji: '🇮🇲',
    id: 'IM',
    name_zh_TW: '曼島',
    name_th: 'เกาะแมน',
  },
  {
    name: 'Israel',
    dial_code: '+972',
    emoji: '🇮🇱',
    id: 'IL',
    name_zh_TW: '以色列',
    name_th: 'อิสราเอล',
  },
  {
    name: 'Italy',
    dial_code: '+39',
    emoji: '🇮🇹',
    id: 'IT',
    name_zh_TW: '義大利',
    name_th: 'อิตาลี',
  },
  {
    name: 'Jamaica',
    dial_code: '+1876',
    emoji: '🇯🇲',
    id: 'JM',
    name_zh_TW: '牙買加',
    name_th: 'จาไมกา',
  },
  {
    name: 'Japan',
    dial_code: '+81',
    emoji: '🇯🇵',
    id: 'JP',
    name_zh_TW: '日本',
    name_th: 'ญี่ปุ่น',
  },
  {
    name: 'Jersey',
    dial_code: '+44',
    emoji: '🇯🇪',
    id: 'JE',
    name_zh_TW: '澤西島',
    name_th: 'เจอร์ซีย์',
  },
  {
    name: 'Jordan',
    dial_code: '+962',
    emoji: '🇯🇴',
    id: 'JO',
    name_zh_TW: '約旦',
    name_th: 'จอร์แดน',
  },
  {
    name: 'Kazakhstan',
    dial_code: '+77',
    emoji: '🇰🇿',
    id: 'KZ',
    name_zh_TW: '哈薩克',
    name_th: 'คาซัคสถาน',
  },
  {
    name: 'Kenya',
    dial_code: '+254',
    emoji: '🇰🇪',
    id: 'KE',
    name_zh_TW: '肯亞',
    name_th: 'เคนยา',
  },
  {
    name: 'Kiribati',
    dial_code: '+686',
    emoji: '🇰🇮',
    id: 'KI',
    name_zh_TW: '吉里巴斯',
    name_th: 'คิริบาติ',
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dial_code: '+850',
    emoji: '🇰🇵',
    id: 'KP',
    name_zh_TW: '朝鮮民主主義人民共和國',
    name_th: 'สาธารณรัฐประชาธิปไตยประชาชนเกาหลี',
  },
  {
    name: 'Korea, Republic of South Korea',
    dial_code: '+82',
    emoji: '🇰🇷',
    id: 'KR',
    name_zh_TW: '韓國',
    name_th: 'สาธารณรัฐเกาหลี',
  },
  {
    name: 'Kuwait',
    dial_code: '+965',
    emoji: '🇰🇼',
    id: 'KW',
    name_zh_TW: '科威特',
    name_th: 'คูเวต',
  },
  {
    name: 'Kyrgyzstan',
    dial_code: '+996',
    emoji: '🇰🇬',
    id: 'KG',
    name_zh_TW: '吉爾吉斯斯坦',
    name_th: 'คีร์กีซสถาน',
  },
  {
    name: 'Laos',
    dial_code: '+856',
    emoji: '🇱🇦',
    id: 'LA',
    name_zh_TW: '寮國人民民主共和國',
    name_th: 'สาธารณรัฐประชาธิปไตยประชาชนลาว',
  },
  {
    name: 'Latvia',
    dial_code: '+371',
    emoji: '🇱🇻',
    id: 'LV',
    name_zh_TW: '拉脫維亞',
    name_th: 'ลัตเวีย',
  },
  {
    name: 'Lebanon',
    dial_code: '+961',
    emoji: '🇱🇧',
    id: 'LB',
    name_zh_TW: '黎巴嫩',
    name_th: 'เลบานอน',
  },
  {
    name: 'Lesotho',
    dial_code: '+266',
    emoji: '🇱🇸',
    id: 'LS',
    name_zh_TW: '賴索托',
    name_th: 'เลโซโท',
  },
  {
    name: 'Liberia',
    dial_code: '+231',
    emoji: '🇱🇷',
    id: 'LR',
    name_zh_TW: '賴比瑞亞',
    name_th: 'ไลบีเรีย',
  },
  {
    name: 'Libyan Arab Jamahiriya',
    dial_code: '+218',
    emoji: '🇱🇾',
    id: 'LY',
    name_zh_TW: '阿拉伯利比亞民眾國',
    name_th: 'สาธารณรัฐอาหรับลิเบีย',
  },
  {
    name: 'Liechtenstein',
    dial_code: '+423',
    emoji: '🇱🇮',
    id: 'LI',
    name_zh_TW: '列支敦斯登',
    name_th: 'ลิกเตนสไตน์',
  },
  {
    name: 'Lithuania',
    dial_code: '+370',
    emoji: '🇱🇹',
    id: 'LT',
    name_zh_TW: '立陶宛',
    name_th: 'ลิทัวเนีย',
  },
  {
    name: 'Luxembourg',
    dial_code: '+352',
    emoji: '🇱🇺',
    id: 'LU',
    name_zh_TW: '盧森堡',
    name_th: 'ลักเซมเบิร์ก',
  },
  {
    name: 'Macao',
    dial_code: '+853',
    emoji: '🇲🇴',
    id: 'MO',
    name_zh_TW: '澳門',
    name_th: 'มาเก๊า',
  },
  {
    name: 'Macedonia',
    dial_code: '+389',
    emoji: '🇲🇰',
    id: 'MK',
    name_zh_TW: '北馬其頓共和國',
    name_th: 'มาซิโดเนีย, สาธารณรัฐยูโกสลาเวียเดิม',
  },
  {
    name: 'Madagascar',
    dial_code: '+261',
    emoji: '🇲🇬',
    id: 'MG',
    name_zh_TW: '馬達加斯加',
    name_th: 'มาดากัสการ์',
  },
  {
    name: 'Malawi',
    dial_code: '+265',
    emoji: '🇲🇼',
    id: 'MW',
    name_zh_TW: '馬拉威',
    name_th: 'มาลาวี',
  },
  {
    name: 'Malaysia',
    dial_code: '+60',
    emoji: '🇲🇾',
    id: 'MY',
    name_zh_TW: '馬來西亞',
    name_th: 'มาเลเซีย',
  },
  {
    name: 'Maldives',
    dial_code: '+960',
    emoji: '🇲🇻',
    id: 'MV',
    name_zh_TW: '馬爾地夫',
    name_th: 'มัลดีฟส์',
  },
  {
    name: 'Mali',
    dial_code: '+223',
    emoji: '🇲🇱',
    id: 'ML',
    name_zh_TW: '馬利',
    name_th: 'มาลี',
  },
  {
    name: 'Malta',
    dial_code: '+356',
    emoji: '🇲🇹',
    id: 'MT',
    name_zh_TW: '馬爾他',
    name_th: 'มอลตา',
  },
  {
    name: 'Marshall Islands',
    dial_code: '+692',
    emoji: '🇲🇭',
    id: 'MH',
    name_zh_TW: '馬紹爾群島',
    name_th: 'หมู่เกาะมาร์แชล',
  },
  {
    name: 'Martinique',
    dial_code: '+596',
    emoji: '🇲🇶',
    id: 'MQ',
    name_zh_TW: '馬提尼克島',
    name_th: 'มาร์ตินีก',
  },
  {
    name: 'Mauritania',
    dial_code: '+222',
    emoji: '🇲🇷',
    id: 'MR',
    name_zh_TW: '茅利塔尼亞',
    name_th: 'มอริเตเนีย',
  },
  {
    name: 'Mauritius',
    dial_code: '+230',
    emoji: '🇲🇺',
    id: 'MU',
    name_zh_TW: '模里西斯',
    name_th: 'มอริเชียส',
  },
  {
    name: 'Mayotte',
    dial_code: '+262',
    emoji: '🇾🇹',
    id: 'YT',
    name_zh_TW: '馬約特島',
    name_th: 'มาโยต',
  },
  {
    name: 'Mexico',
    dial_code: '+52',
    emoji: '🇲🇽',
    id: 'MX',
    name_zh_TW: '墨西哥',
    name_th: 'เม็กซิโก',
  },
  {
    name: 'Micronesia, Federated States of Micronesia',
    dial_code: '+691',
    emoji: '🇫🇲',
    id: 'FM',
    name_zh_TW: '密克羅尼西亞聯邦',
    name_th: 'มิโครนีเซีย, สหรัฐอเมริกา',
  },
  {
    name: 'Moldova',
    dial_code: '+373',
    emoji: '🇲🇩',
    id: 'MD',
    name_zh_TW: '摩爾多瓦共和國',
    name_th: 'สาธารณรัฐมอลโดวา',
  },
  {
    name: 'Monaco',
    dial_code: '+377',
    emoji: '🇲🇨',
    id: 'MC',
    name_zh_TW: '摩納哥',
    name_th: 'มอนาโก',
  },
  {
    name: 'Mongolia',
    dial_code: '+976',
    emoji: '🇲🇳',
    id: 'MN',
    name_zh_TW: '蒙古',
    name_th: 'มองโกเลีย',
  },
  {
    name: 'Montenegro',
    dial_code: '+382',
    emoji: '🇲🇪',
    id: 'ME',
    name_zh_TW: '蒙特內哥羅',
    name_th: 'มอนเตเนโกร',
  },
  {
    name: 'Montserrat',
    dial_code: '+1664',
    emoji: '🇲🇸',
    id: 'MS',
    name_zh_TW: '蒙特塞拉特島',
    name_th: 'มอนต์เซอร์รัต',
  },
  {
    name: 'Morocco',
    dial_code: '+212',
    emoji: '🇲🇦',
    id: 'MA',
    name_zh_TW: '摩洛哥',
    name_th: 'โมร็อกโก',
  },
  {
    name: 'Mozambique',
    dial_code: '+258',
    emoji: '🇲🇿',
    id: 'MZ',
    name_zh_TW: '莫三比克',
    name_th: 'โมซัมบิก',
  },
  {
    name: 'Myanmar',
    dial_code: '+95',
    emoji: '🇲🇲',
    id: 'MM',
    name_zh_TW: '緬甸',
    name_th: 'เมียนมาร์',
  },
  {
    name: 'Namibia',
    emoji: '🇳🇦',
    dial_code: '+264',
    id: 'NA',
    name_zh_TW: '納米比亞',
    name_th: 'นามิเบีย',
  },
  {
    name: 'Nauru',
    dial_code: '+674',
    emoji: '🇳🇷',
    id: 'NR',
    name_zh_TW: '諾魯',
    name_th: 'นาวรู',
  },
  {
    name: 'Nepal',
    dial_code: '+977',
    emoji: '🇳🇵',
    id: 'NP',
    name_zh_TW: '尼泊爾',
    name_th: 'เนปาล',
  },
  {
    name: 'Netherlands',
    dial_code: '+31',
    emoji: '🇳🇱',
    id: 'NL',
    name_zh_TW: '荷蘭',
    name_th: 'เนเธอร์แลนด์',
  },
  {
    name: 'Netherlands Antilles',
    dial_code: '+599',
    emoji: '🇧🇶',
    id: 'AN',
    name_zh_TW: '荷屬安的列斯',
    name_th: 'แอนทิลลิสเนเธอร์แลนด์',
  },
  {
    name: 'New Caledonia',
    dial_code: '+687',
    emoji: '🇳🇨',
    id: 'NC',
    name_zh_TW: '新喀裡多尼亞',
    name_th: 'นิวแคลิโดเนีย',
  },
  {
    name: 'New Zealand',
    dial_code: '+64',
    emoji: '🇳🇿',
    id: 'NZ',
    name_zh_TW: '紐西蘭',
    name_th: 'นิวซีแลนด์',
  },
  {
    name: 'Nicaragua',
    dial_code: '+505',
    emoji: '🇳🇮',
    id: 'NI',
    name_zh_TW: '尼加拉瓜',
    name_th: 'นิการากัว',
  },
  {
    name: 'Niger',
    dial_code: '+227',
    emoji: '🇳🇪',
    id: 'NE',
    name_zh_TW: '尼日',
    name_th: 'ไนเจอร์',
  },
  {
    name: 'Nigeria',
    dial_code: '+234',
    emoji: '🇳🇬',
    id: 'NG',
    name_zh_TW: '奈及利亞',
    name_th: 'ไนจีเรีย',
  },
  {
    name: 'Niue',
    dial_code: '+683',
    emoji: '🇳🇺',
    id: 'NU',
    name_zh_TW: '紐埃',
    name_th: 'นีอูเอ',
  },
  {
    name: 'Norfolk Island',
    dial_code: '+672',
    emoji: '🇳🇫',
    id: 'NF',
    name_zh_TW: '諾福克島',
    name_th: 'เกาะนอร์ฟอล์ก',
  },
  {
    name: 'Northern Mariana Islands',
    dial_code: '+1670',
    emoji: '🇲🇵',
    id: 'MP',
    name_zh_TW: '北馬裡亞納群島',
    name_th: 'หมู่เกาะนอร์เทิร์นมาเรียนา',
  },
  {
    name: 'Norway',
    dial_code: '+47',
    emoji: '🇳🇴',
    id: 'NO',
    name_zh_TW: '挪威',
    name_th: 'นอร์เวย์',
  },
  {
    name: 'Oman',
    dial_code: '+968',
    emoji: '🇴🇲',
    id: 'OM',
    name_zh_TW: '阿曼',
    name_th: 'โอมาน',
  },
  {
    name: 'Pakistan',
    dial_code: '+92',
    emoji: '🇵🇰',
    id: 'PK',
    name_zh_TW: '巴基斯坦',
    name_th: 'ปากีสถาน',
  },
  {
    name: 'Palau',
    dial_code: '+680',
    emoji: '🇵🇼',
    id: 'PW',
    name_zh_TW: '帛琉',
    name_th: 'ปะลาว',
  },
  {
    name: 'Palestinian Territory, Occupied',
    dial_code: '+970',
    emoji: '🇵🇸',
    id: 'PS',
    name_zh_TW: '巴勒斯坦領土',
    name_th: 'ดินแดนปาเลสไตน์ที่ถูกยึดครอง',
  },
  {
    name: 'Panama',
    dial_code: '+507',
    emoji: '🇵🇦',
    id: 'PA',
    name_zh_TW: '巴拿馬',
    name_th: 'ปานามา',
  },
  {
    name: 'Papua New Guinea',
    dial_code: '+675',
    emoji: '🇵🇬',
    id: 'PG',
    name_zh_TW: '巴布亞紐幾內亞',
    name_th: 'ปาปัวนิวกินี',
  },
  {
    name: 'Paraguay',
    dial_code: '+595',
    emoji: '🇵🇾',
    id: 'PY',
    name_zh_TW: '巴拉圭',
    name_th: 'ปารากวัย',
  },
  {
    name: 'Peru',
    dial_code: '+51',
    emoji: '🇵🇪',
    id: 'PE',
    name_zh_TW: '秘魯',
    name_th: 'เปรู',
  },
  {
    name: 'Philippines',
    dial_code: '+63',
    emoji: '🇵🇭',
    id: 'PH',
    name_zh_TW: '菲律賓',
    name_th: 'ฟิลิปปินส์',
  },
  {
    name: 'Pitcairn',
    dial_code: '+872',
    emoji: '🇵🇳',
    id: 'PN',
    name_zh_TW: '皮特肯群島',
    name_th: 'หมู่เกาะพิตแคร์น',
  },
  {
    name: 'Poland',
    dial_code: '+48',
    emoji: '🇵🇱',
    id: 'PL',
    name_zh_TW: '波蘭',
    name_th: 'โปแลนด์',
  },
  {
    name: 'Portugal',
    dial_code: '+351',
    emoji: '🇵🇹',
    id: 'PT',
    name_zh_TW: '葡萄牙',
    name_th: 'โปรตุเกส',
  },
  {
    name: 'Puerto Rico',
    dial_code: '+1939',
    emoji: '🇵🇷',
    id: 'PR',
    name_zh_TW: '波多黎各',
    name_th: 'เปอร์โตริโก',
  },
  {
    name: 'Qatar',
    dial_code: '+974',
    emoji: '🇶🇦',
    id: 'QA',
    name_zh_TW: '卡達',
    name_th: 'กาตาร์',
  },
  {
    name: 'Romania',
    dial_code: '+40',
    emoji: '🇷🇴',
    id: 'RO',
    name_zh_TW: '羅馬尼亞',
    name_th: 'โรมาเนีย',
  },
  {
    name: 'Russia',
    dial_code: '+7',
    emoji: '🇷🇺',
    id: 'RU',
    name_zh_TW: '俄羅斯',
    name_th: 'รัสเซีย',
  },
  {
    name: 'Rwanda',
    dial_code: '+250',
    emoji: '🇷🇼',
    id: 'RW',
    name_zh_TW: '盧安達',
    name_th: 'รวันดา',
  },
  {
    name: 'Reunion',
    dial_code: '+262',
    emoji: '🇷🇪',
    id: 'RE',
    name_zh_TW: '留尼旺',
    name_th: 'เรอูนียง',
  },
  {
    name: 'Saint Barthelemy',
    dial_code: '+590',
    emoji: '🇧🇱',
    id: 'BL',
    name_zh_TW: '聖巴瑟米',
    name_th: 'แซงต์บาร์เทเลมี',
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    dial_code: '+290',
    emoji: '🇸🇭',
    id: 'SH',
    name_zh_TW: '聖赫勒拿島',
    name_th: 'เซนต์เฮเลนา',
  },
  {
    name: 'Saint Kitts and Nevis',
    dial_code: '+1869',
    emoji: '🇰🇳',
    id: 'KN',
    name_zh_TW: '聖克里斯多福及尼維斯',
    name_th: 'เซนต์คิตส์และเนวิส',
  },
  {
    name: 'Saint Lucia',
    dial_code: '+1758',
    emoji: '🇱🇨',
    id: 'LC',
    name_zh_TW: '聖露西亞',
    name_th: 'เซนต์ลูเซีย',
  },
  {
    name: 'Saint Martin',
    dial_code: '+590',
    emoji: '🇲🇫',
    id: 'MF',
    name_zh_TW: '聖馬丁島',
    name_th: 'แซงต์มาร์ติน',
  },
  {
    name: 'Saint Pierre and Miquelon',
    dial_code: '+508',
    emoji: '🇵🇲',
    id: 'PM',
    name_zh_TW: '聖皮埃爾和密克隆群島',
    name_th: 'เซนต์ปิแอร์และมีเกอลอน',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    dial_code: '+1784',
    emoji: '🇻🇨',
    id: 'VC',
    name_zh_TW: '聖文森及格瑞那丁',
    name_th: 'เซนต์วินเซนต์และเกรนาดีนส์',
  },
  {
    name: 'Samoa',
    dial_code: '+685',
    emoji: '🇼🇸',
    id: 'WS',
    name_zh_TW: '薩摩亞',
    name_th: 'ซามัว',
  },
  {
    name: 'San Marino',
    dial_code: '+378',
    emoji: '🇸🇲',
    id: 'SM',
    name_zh_TW: '聖馬利諾',
    name_th: 'ซานมาริโน',
  },
  {
    name: 'Sao Tome and Principe',
    dial_code: '+239',
    emoji: '🇸🇹',
    id: 'ST',
    name_zh_TW: '聖多美和普林西比',
    name_th: 'เซาตูเมและปรินซิเป',
  },
  {
    name: 'Saudi Arabia',
    dial_code: '+966',
    emoji: '🇸🇦',
    id: 'SA',
    name_zh_TW: '沙烏地阿拉伯',
    name_th: 'ซาอุดีอาระเบีย',
  },
  {
    name: 'Senegal',
    dial_code: '+221',
    emoji: '🇸🇳',
    id: 'SN',
    name_zh_TW: '塞內加爾',
    name_th: 'เซเนกัล',
  },
  {
    name: 'Serbia',
    dial_code: '+381',
    emoji: '🇷🇸',
    id: 'RS',
    name_zh_TW: '塞爾維亞',
    name_th: 'เซอร์เบีย',
  },
  {
    name: 'Seychelles',
    dial_code: '+248',
    emoji: '🇸🇨',
    id: 'SC',
    name_zh_TW: '塞席爾',
    name_th: 'เซเชลส์',
  },
  {
    name: 'Sierra Leone',
    dial_code: '+232',
    emoji: '🇸🇱',
    id: 'SL',
    name_zh_TW: '獅子山',
    name_th: 'เซียร์ราลีโอน',
  },
  {
    name: 'Singapore',
    dial_code: '+65',
    emoji: '🇸🇬',
    id: 'SG',
    name_zh_TW: '新加坡',
    name_th: 'สิงคโปร์',
  },
  {
    name: 'Slovakia',
    dial_code: '+421',
    emoji: '🇸🇰',
    id: 'SK',
    name_zh_TW: '斯洛伐克',
    name_th: 'สโลวาเกีย',
  },
  {
    name: 'Slovenia',
    dial_code: '+386',
    emoji: '🇸🇮',
    id: 'SI',
    name_zh_TW: '斯洛維尼亞',
    name_th: 'สโลวีเนีย',
  },
  {
    name: 'Solomon Islands',
    dial_code: '+677',
    emoji: '🇸🇧',
    id: 'SB',
    name_zh_TW: '索羅門群島',
    name_th: 'หมู่เกาะโซโลมอน',
  },
  {
    name: 'Somalia',
    dial_code: '+252',
    emoji: '🇸🇴',
    id: 'SO',
    name_zh_TW: '索馬利亞',
    name_th: 'โซมาเลีย',
  },
  {
    name: 'South Africa',
    dial_code: '+27',
    emoji: '🇿🇦',
    id: 'ZA',
    name_zh_TW: '南非',
    name_th: 'แอฟริกาใต้',
  },
  {
    name: 'South Sudan',
    dial_code: '+211',
    emoji: '🇸🇸',
    id: 'SS',
    name_zh_TW: '南蘇丹',
    name_th: 'ซูดานใต้',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    dial_code: '+500',
    emoji: '🇬🇸',
    id: 'GS',
    name_zh_TW: '南喬治亞州和南桑威奇群島',
    name_th: 'หมู่เกาะเซาท์จอร์เจียและเซาท์แซนด์วิช',
  },
  {
    name: 'Spain',
    dial_code: '+34',
    emoji: '🇪🇸',
    id: 'ES',
    name_zh_TW: '西班牙',
    name_th: 'สเปน',
  },
  {
    name: 'Sri Lanka',
    dial_code: '+94',
    emoji: '🇱🇰',
    id: 'LK',
    name_zh_TW: '斯里蘭卡',
    name_th: 'ศรีลังกา',
  },
  {
    name: 'Sudan',
    dial_code: '+249',
    emoji: '🇸🇩',
    id: 'SD',
    name_zh_TW: '蘇丹',
    name_th: 'ซูดาน',
  },
  {
    name: 'Suriname',
    dial_code: '+597',
    emoji: '🇸🇷',
    id: 'SR',
    name_zh_TW: '蘇利南',
    name_th: 'ซูรินาม',
  },
  {
    name: 'Svalbard and Jan Mayen',
    dial_code: '+47',
    emoji: '🇸🇯',
    id: 'SJ',
    name_zh_TW: '斯瓦爾巴群島和揚馬延島',
    name_th: 'สฟาลบาร์ดและยานมาเยน',
  },
  {
    name: 'Swaziland',
    dial_code: '+268',
    emoji: '🇸🇿',
    id: 'SZ',
    name_zh_TW: '史瓦帝尼王國',
    name_th: 'สวาซิแลนด์',
  },
  {
    name: 'Sweden',
    dial_code: '+46',
    emoji: '🇸🇪',
    id: 'SE',
    name_zh_TW: '瑞典',
    name_th: 'สวีเดน',
  },
  {
    name: 'Switzerland',
    dial_code: '+41',
    emoji: '🇨🇭',
    id: 'CH',
    name_zh_TW: '瑞士',
    name_th: 'สวิตเซอร์แลนด์',
  },
  {
    name: 'Syrian Arab Republic',
    dial_code: '+963',
    emoji: '🇸🇾',
    id: 'SY',
    name_zh_TW: '阿拉伯敘利亞共和國',
    name_th: 'สาธารณรัฐอาหรับซีเรีย',
  },
  {
    name: 'Taiwan',
    dial_code: '+886',
    emoji: '🇹🇼',
    id: 'TW',
    name_zh_TW: '台灣',
    name_th: 'ไต้หวัน, สาธารณรัฐจีน',
  },
  {
    name: 'Tajikistan',
    dial_code: '+992',
    emoji: '🇹🇯',
    id: 'TJ',
    name_zh_TW: '塔吉克',
    name_th: 'ทาจิกิสถาน',
  },
  {
    name: 'Tanzania, United Republic of Tanzania',
    dial_code: '+255',
    emoji: '🇹🇿',
    id: 'TZ',
    name_zh_TW: '坦尚尼亞聯合共和國',
    name_th: 'แทนซาเนีย, สาธารณรัฐแห่งสหภาพ',
  },
  {
    name: 'Thailand',
    dial_code: '+66',
    emoji: '🇹🇭',
    id: 'TH',
    name_zh_TW: '泰國',
    name_th: 'ไทยแลนด์',
  },
  {
    name: 'Timor-Leste',
    dial_code: '+670',
    emoji: '🇹🇱',
    id: 'TL',
    name_zh_TW: '東帝汶',
    name_th: 'ติมอร์-เลสเต',
  },
  {
    name: 'Togo',
    dial_code: '+228',
    emoji: '🇹🇬',
    id: 'TG',
    name_zh_TW: '多哥',
    name_th: 'โตโก',
  },
  {
    name: 'Tokelau',
    dial_code: '+690',
    emoji: '🇹🇰',
    id: 'TK',
    name_zh_TW: '托克勞',
    name_th: 'โตเคลาอู',
  },
  {
    name: 'Tonga',
    dial_code: '+676',
    emoji: '🇹🇴',
    id: 'TO',
    name_zh_TW: '東加',
    name_th: 'ตองกา',
  },
  {
    name: 'Trinidad and Tobago',
    dial_code: '+1868',
    emoji: '🇹🇹',
    id: 'TT',
    name_zh_TW: '千里達及托巴哥',
    name_th: 'ตรินิแดดและโตเบโก',
  },
  {
    name: 'Tunisia',
    dial_code: '+216',
    emoji: '🇹🇳',
    id: 'TN',
    name_zh_TW: '突尼西亞',
    name_th: 'ตูนิส',
  },
  {
    name: 'Turkey',
    dial_code: '+90',
    emoji: '🇹🇷',
    id: 'TR',
    name_zh_TW: '土耳其',
    name_th: 'ตุรกี',
  },
  {
    name: 'Turkmenistan',
    dial_code: '+993',
    emoji: '🇹🇲',
    id: 'TM',
    name_zh_TW: '土庫曼',
    name_th: 'เติร์กเมนิสถาน',
  },
  {
    name: 'Turks and Caicos Islands',
    dial_code: '+1649',
    emoji: '🇹🇨',
    id: 'TC',
    name_zh_TW: '特克斯和凱科斯群島',
    name_th: 'หมู่เกาะเติกส์และหมู่เกาะเคคอส',
  },
  {
    name: 'Tuvalu',
    dial_code: '+688',
    emoji: '🇹🇻',
    id: 'TV',
    name_zh_TW: '吐瓦魯',
    name_th: 'ตูวาลู',
  },
  {
    name: 'Uganda',
    dial_code: '+256',
    emoji: '🇺🇬',
    id: 'UG',
    name_zh_TW: '烏干達',
    name_th: 'ยูกันดา',
  },
  {
    name: 'Ukraine',
    dial_code: '+380',
    emoji: '🇺🇦',
    id: 'UA',
    name_zh_TW: '烏克蘭',
    name_th: 'ยูเครน',
  },
  {
    name: 'United Arab Emirates',
    dial_code: '+971',
    emoji: '🇦🇪',
    id: 'AE',
    name_zh_TW: '阿拉伯聯合大公國',
    name_th: 'สหรัฐอาหรับเอมิเรตส์',
  },
  {
    name: 'United Kingdom',
    dial_code: '+44',
    emoji: '🇬🇧',
    id: 'GB',
    name_zh_TW: '英國',
    name_th: 'สหราชอาณาจักร',
  },
  {
    name: 'United States',
    dial_code: '+1',
    emoji: '🇺🇸',
    id: 'US',
    name_zh_TW: '美國',
    name_th: 'สหรัฐอเมริกา',
  },
  {
    name: 'Uruguay',
    dial_code: '+598',
    emoji: '🇺🇾',
    id: 'UY',
    name_zh_TW: '烏拉圭',
    name_th: 'อุรุกวัย',
  },
  {
    name: 'Uzbekistan',
    dial_code: '+998',
    emoji: '🇺🇿',
    id: 'UZ',
    name_zh_TW: '烏茲別克',
    name_th: 'อุซเบกิสถาน',
  },
  {
    name: 'Vanuatu',
    dial_code: '+678',
    emoji: '🇻🇺',
    id: 'VU',
    name_zh_TW: '萬那杜',
    name_th: 'วานูอาตู',
  },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    dial_code: '+58',
    emoji: '🇻🇪',
    id: 'VE',
    name_zh_TW: '委內瑞拉',
    name_th: 'เวเนซุเอลา',
  },
  {
    name: 'Vietnam',
    dial_code: '+84',
    emoji: '🇻🇳',
    id: 'VN',
    name_zh_TW: '越南',
    name_th: 'เวียดนาม',
  },
  {
    name: 'Virgin Islands, British',
    dial_code: '+1284',
    emoji: '🇻🇬',
    id: 'VG',
    name_zh_TW: '英國維京群島',
    name_th: 'หมู่เกาะเวอร์จิน, อังกฤษ',
  },
  {
    name: 'Virgin Islands, U.S.',
    dial_code: '+1340',
    emoji: '🇻🇮',
    id: 'VI',
    name_zh_TW: '美國維京群島',
    name_th: 'หมู่เกาะเวอร์จิน, สหรัฐอเมริกา',
  },
  {
    name: 'Wallis and Futuna',
    dial_code: '+681',
    emoji: '🇼🇫',
    id: 'WF',
    name_zh_TW: '瓦利斯和富圖納群島',
    name_th: 'วอลลิสและฟูตูนา',
  },
  {
    name: 'Yemen',
    dial_code: '+967',
    emoji: '🇾🇪',
    id: 'YE',
    name_zh_TW: '葉門',
    name_th: 'เยเมน',
  },
  {
    name: 'Zambia',
    dial_code: '+260',
    emoji: '🇿🇲',
    id: 'ZM',
    name_zh_TW: '尚比亞',
    name_th: 'แซมเบีย',
  },
  {
    name: 'Zimbabwe',
    dial_code: '+263',
    emoji: '🇿🇼',
    id: 'ZW',
    name_zh_TW: '辛巴威',
    name_th: 'ซิมบับเว',
  },
];

export default countries;
