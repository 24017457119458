import { frontendURL } from '../../../../helper/URLHelper';

const Index = () => import('./Index.vue');

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/profanity'),
      name: 'settings_profanity',
      meta: {
        permissions: ['administrator', 'agent'],
      },
      component: Index,
    },
  ],
};
